import React from "react"
import tw, { css } from "twin.macro"
import { createGlobalStyle } from "styled-components"

export const SystemFonts = createGlobalStyle`
  ${css`
    @font-face {
      font-family: "Faune Display";
      src: url("/fonts/faune/eot/Faune-Display_Black.eot");
      src: url("/fonts/faune/eot/Faune-Display_Black.eot?#iefix") format("embedded-opentype"),
        url("/fonts/faune/woff/Faune-Display_Black.woff") format("woff"),
        url("/fonts/faune/woff2/Faune-Display_Black.woff2") format("woff2"),
        url("/fonts/faune/ttf/Faune-Display_Black.ttf") format("truetype"),
        url("/fonts/faune/svg/Faune-Display_Black.svg#svgFontName") format("svg");
      font-weight: 900;
      font-style: normal;
    }

    @font-face {
      font-family: "Faune Display Italic";
      src: url("/fonts/faune/eot/Faune-Display_Bold_Italic.eot");
      src: url("/fonts/faune/eot/Faune-Display_Bold_Italic.eot?#iefix") format("embedded-opentype"),
        url("/fonts/faune/woff/Faune-Display_Bold_Italic.woff") format("woff"),
        url("/fonts/faune/woff2/Faune-Display_Bold_Italic.woff2") format("woff2"),
        url("/fonts/faune/ttf/Faune-Display_Bold_Italic.ttf") format("truetype"),
        url("/fonts/faune/svg/Faune-Display_Bold_Italic.svg#svgFontName") format("svg");
      font-weight: bold;
      font-style: italic;
    }

    @font-face {
      font-family: "Faune Display Thin";
      src: url("/fonts/faune/eot/Faune-Display_Thin.eot");
      src: url("/fonts/faune/eot/Faune-Display_Thin.eot?#iefix") format("embedded-opentype"),
        url("/fonts/faune/woff/Faune-Display_Thin.woff") format("woff"),
        url("/fonts/faune/woff2/Faune-Display_Thin.woff2") format("woff2"),
        url("/fonts/faune/ttf/Faune-Display_Thin.ttf") format("truetype"),
        url("/fonts/faune/svg/Faune-Display_Thin.svg#svgFontName") format("svg");
      font-weight: 100;
      font-style: normal;
    }

    @font-face {
      font-family: "Faune";
      src: url("/fonts/fauneOblique/OpenType-PS/Faune-TextBold-oblique.otf") format("opentype");
      src: url("/fonts/fauneOblique/Web-TT/Faune-TextBold-oblique.ttf");
      font-weight: bold;
      font-style: italic;
    }

    @font-face {
      font-family: "Faune";
      src: url("/fonts/faune/eot/Faune-Text_Bold.eot");
      src: url("/fonts/faune/eot/Faune-Text_Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/faune/woff/Faune-Text_Bold.woff") format("woff"),
        url("/fonts/faune/woff2/Faune-Text_Bold.woff2") format("woff2"),
        url("/fonts/faune/ttf/Faune-Text_Bold.ttf") format("truetype"),
        url("/fonts/faune/svg/Faune-Text_Bold.svg#svgFontName") format("svg");
      font-weight: bold;
      font-style: normal;
    }

    @font-face {
      font-family: "Faune";
      src: url("/fonts/fauneOblique/OpenType-PS/Faune-TextRegular-oblique.otf") format("opentype");
      src: url("/fonts/fauneOblique/Web-TT/Faune-TextRegular-oblique.ttf");
      font-weight: normal;
      font-style: italic;
    }

    @font-face {
      font-family: "Faune";
      src: url("/fonts/faune/eot/Faune-Text_Regular.eot");
      src: url("/fonts/faune/eot/Faune-Text_Regular.eot?#iefix") format("embedded-opentype"),
        url("/fonts/faune/woff/Faune-Text_Regular.woff") format("woff"),
        url("/fonts/faune/woff2/Faune-Text_Regular.woff2") format("woff2"),
        url("/fonts/faune/ttf/Faune-Text_Regular.ttf") format("truetype"),
        url("/fonts/faune/svg/Faune-Text_Regular.svg#svgFontName") format("svg");
      font-weight: normal;
      font-style: normal;
    }
  `}
`
export const ThemeFontFamilyTokens = createGlobalStyle({
  ":root": {
    "--font-family-sans": "Faune",
    "--font-family-serif": "Spectral",
    "--font-family-mono": "SFMono-Regular",
    "--font-family-display": "Spectral",
    "--font-family-body": "Spectral",
  },
})

export const ThemeFontSizeTokens = createGlobalStyle({
  ":root": {
    "--font-size-2xs": "12px",
    "--font-size-xs": "14px",
    "--font-size-sm": "18px",
    "--font-size-md": "20px",
    "--font-size-base": "22px",
    "--font-size-lg": "22px",
    "--font-size-xl": "24px",
    "--font-size-2xl": "30px",
    "--font-size-2xl-half": "48px",
    "--font-size-3xl": "48px",
    "--font-size-4xl": "65px",
    "--font-size-h1": "72px",
    "--font-size-hero": "80px",

    "--line-height-2xs": "16px",
    "--line-height-xs": "22px",
    "--line-height-sm": "26px",
    "--line-height-md": "30px",
    "--line-height-base": "30px",
    "--line-height-lg": "30px",
    "--line-height-xl": "36px",
    "--line-height-2xl": "40px",
    "--line-height-2xl-half": "54px",
    "--line-height-3xl": "55px",
    "--line-height-4xl": "80px",
    "--line-height-h1": "90px",

    "--font-weight-thin": "100",
    "--font-weight-extralight": "200",
    "--font-weight-light": "300",
    "--font-weight-normal": "400",
    "--font-weight-medium": "500",
    "--font-weight-semibold": "600",
    "--font-weight-bold": "700",
    "--font-weight-extrabold": "800",
    "--font-weight-black": "900",
  },
})
