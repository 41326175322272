import type { AppProps } from "next/app"
import Head from "next/head"
import tw from "twin.macro"
import Footer from "../components/footer"
import GlobalStyles from "../components/globalStyles"
import Nav from "../components/nav"
import { useState } from "react"
import { WorkContext } from "../util/context"
import { DefaultSeo } from "next-seo"
import { Analytics } from "@vercel/analytics/react"
import SEO from "../next-seo.config"

function App({ Component, pageProps }: AppProps) {
  const [workctx, setWorkCtx] = useState<string | undefined>()
  return (
    <>
      <GlobalStyles {...pageProps} />
      <DefaultSeo {...SEO} />
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <WorkContext.Provider value={{ workctx, setWorkCtx }}>
        <>
          {process.env.NEXT_PUBLIC_VERCEL_ENV != "production" && (
            <div tw="bg-yellow-400 py-0.5">
              <p tw="text-center font-sans uppercase text-xs font-bold">Preview</p>
            </div>
          )}
          <Nav />
          <main>
            <Component {...pageProps} />
            <Analytics />
          </main>
          <Footer />
        </>
      </WorkContext.Provider>
    </>
  )
}

export default App
