// components/GlobalStyles.js
import { createGlobalStyle } from "styled-components"
import tw, { css, GlobalStyles as BaseStyles } from "twin.macro"
import { SystemFonts, ThemeFontFamilyTokens, ThemeFontSizeTokens } from "./fonts"
import { ZoomStyles } from "./zoom"

const CustomFonts = createGlobalStyle`

  body {
    ${tw`
      bg-background
      antialiased
      text-lg
      font-serif
      font-light
      text-rpd-gray-9
      relative
    `},
    ${css`
      letter-spacing: -0.04em;
    `}

    vercel-live-feedback{
      display:none;
    }
    
  }
  a{
    cursor: pointer;
    ${tw`
      hover:underline
    `}
  }
  
`

const GlobalStyles = () => (
  <>
    <SystemFonts />
    <ThemeFontFamilyTokens />
    <ThemeFontSizeTokens />
    <BaseStyles />
    <CustomFonts />
    <ZoomStyles />
  </>
)

export default GlobalStyles
