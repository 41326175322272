export default {
  titleTemplate: "%s | The Richard Pousette-Dart Foundation",
  defaultTitle: "The Richard Pousette-Dart Foundation",
  description: "First-generation Abstract Expressionist artist recognized for symbolically-rich paintings.",
  keywords:
    "Richard Pousette-Dart, Artist Foundation, artist, painter, American abstract artist, abstract expressionism, Joanna Pousette-Dart, Jonathan Pousette-Dart, spiritual art, Pace Gallery",
  openGraph: {
    type: "website",
    locale: "en_US",
    type: "website",
    url: "https://www.pousette-dartfoundation.org",
    siteName: "The Richard Pousette-Dart Foundation",
    images: [
      {
        url: "https://www.pousette-dartfoundation.org/images/opengraph/richard-pousette-dart.jpg",
        width: 2000,
        height: 2000,
        alt: "Richard Pousette-Dart",
      },
      {
        url: "https://www.pousette-dartfoundation.org/images/opengraph/richard-pousette-dart-red.jpg",
        width: 2000,
        height: 2000,
        alt: "Richard Pousette-Dart",
      },
    ],
  },
}
