import Link from "next/link"
import { useRouter } from "next/router"
import { useState } from "react"
import slugify from "slugify"
import tw, { css, styled } from "twin.macro"

enum PrimaryNav {
  Artist = "Artist",
  Foundation = "Foundation",
  Features = "Features",
  Resources = "Resources",
  Works = "Works",
}

const navData = [
  { item: PrimaryNav.Artist },
  { item: PrimaryNav.Foundation },
  { item: PrimaryNav.Features },
  { item: PrimaryNav.Resources },
  { item: PrimaryNav.Works },
]

const StyledLogo = styled("h1")(({ isHome }: StyledNavItemsProps) => [
  tw`
    py-2.5
    w-2/3
    tablet:(w-auto)
    text-xs
    uppercase
    tablet:(text-sm block)
    font-sans
    font-bold
    desktop:(py-6)
    flex-none
    leading-5
  `,
  css`
    letter-spacing: 0.1em;
  `,

  isHome && tw`tablet:(hidden)`,
])

interface StyledNavItemsProps {
  open: boolean
  isHome: boolean
}

const StyledNavItems = styled("ul")(({ open, isHome }: StyledNavItemsProps) => [
  tw`hidden font-sans text-xs tablet:(space-x-4 flex items-center w-full) desktop:(space-x-8)`,
  css``,
  isHome ? tw`justify-center` : tw`justify-end`,
  open && [
    tw`p-4 flex flex-col absolute bg-rpd-gray-8 top-24 mobile:(top-20) z-30 w-full right-0 tablet:(p-0 relative top-0 z-0 flex-row)`,
  ],
  ,
])

const Nav = () => {
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const isHome = router.pathname == "/"

  const NavIcon = () => {
    return (
      <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.951172" width="30" height="3" fill="#FCFFFF" />
        <rect x="0.951172" y="9" width="30" height="3" fill="#FCFFFF" />
        <rect x="0.951172" y="18" width="30" height="3" fill="#FCFFFF" />
      </svg>
    )
  }

  const NavMenu = () => {
    const toggle = () => (open ? setOpen(false) : setOpen(true))
    return (
      <div tw="z-50 tablet:(hidden)">
        <button onClick={toggle} tw="flex items-center pt-1 px-3 text-white">
          <NavIcon />
        </button>
      </div>
    )
  }

  const NavItems = () => {
    const toggle = () => open && setOpen(false)
    return (
      <StyledNavItems isHome={isHome} open={open}>
        {navData.map((item, i) => {
          const path = slugify(item.item, { lower: true })
          return (
            <li key={i} css="letter-spacing: 0.1em;">
              <Link
                href={`/${path}`}
                passHref
                tw="uppercase inline-block py-2 tablet:(py-6) hover:(no-underline)"
                onClick={toggle}
              >
                <span tw="hover:(border-b-2 border-white border-solid)">{item.item}</span>
              </Link>
            </li>
          )
        })}
      </StyledNavItems>
    )
  }

  return (
    <nav tw="bg-rpd-gray-8 z-20 sticky top-0 tablet:(relative)">
      <div tw="max-w-screen-desktop-lg desktop-lg:mx-auto px-4 relative">
        <div tw="grid grid-cols-4 desktop:(grid-cols-12) gap-4 desktop:(gap-6 gap-y-0)">
          <div tw="col-span-4 tablet:col-span-12">
            <div tw="text-white flex justify-between items-center">
              <StyledLogo open={open} isHome={isHome}>
                <Link
                  href="/"
                  passHref
                  tw="desktop:(hover:(no-underline border-b-2 border-white border-solid))"
                  css="text-underline-offset: 4px;"
                >
                  <span>The</span> <span tw="block desktop:inline">Richard Pousette-Dart</span> <span>Foundation</span>
                </Link>
              </StyledLogo>

              <NavItems />
              <NavMenu />
            </div>
          </div>
        </div>
      </div>

      <div
        tw="h-8 w-full absolute"
        css={[
          `
          background: linear-gradient(180deg, #303333, rgba(48, 51, 51, -0.5));
        `,
        ]}
        // css="background: linear-gradient(180deg, #303333 11.46%, rgba(217, 217, 217, 0) 100%)"
      ></div>
    </nav>
  )
}
export default Nav
