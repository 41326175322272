import { createContext, Dispatch, SetStateAction, useContext, useState } from "react"

interface WorkContextType {
  workctx: string | undefined
  setWorkCtx: Dispatch<SetStateAction<string | undefined>>
}

export const WorkContext = createContext<WorkContextType>({
  workctx: undefined,
  setWorkCtx: () => {
    return
  },
})

export const useCTX = () => useContext(WorkContext)

// export const CTXProvider = (children: Element) => {
//   const [workctx, setWorkCtx] = useState()

//   // return <WorkContext.Provider value={{ workctx, setWorkCtx }}>{children}</WorkContext.Provider>
// }
